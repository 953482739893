import { toRem } from '@/helpers/toRem';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  Container,
  HStack,
  Img,
  StyleProps,
  Text,
  VStack
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import Slide from '../Slide';
import { FC, useEffect, useState } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';
import { useExtraProps } from '@/pages/_app';
import { BannerType } from '@/types/api/ge-strapi/banner';
import useTranslation from 'next-translate/useTranslation';
import { HTTP } from '../Http';
import {
  getCategoriesHeight,
  getHeaderHeight,
  getJackpotsHeight
} from '@/helpers/header';

type FullSliderProps = BoxProps & {
  categoryId?: string;
  providerId?: string;
  sliderHeight?: number;
};

export const SLIDER_HEIGHT = 380;

const FullSlider: FC<FullSliderProps> = ({
  categoryId = '',
  sliderHeight = SLIDER_HEIGHT,
  ...props
}) => {
  const isMobile = useMediaQuery('(max-width: sm');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [banners, setBanners] = useState<BannerType[]>([]);
  const [isBannerError, setIsBannerError] = useState<boolean>(false);
  const { t, lang } = useTranslation();
  const { license } = useExtraProps();
  const customBulletName = 'custom-catalog-bullet';
  const customBulletActiveName = 'custom-catalog-bullet-active';

  const bulletStyles = css`
    .${customBulletName} {
      display: inline-flex;
      width: 8px;
      height: 8px;
      background: ${isMobile ? 'transparent' : 'rgba(0,0,0,.5)'};
      border-radius: 50%;
      margin: 0 5px;
      border: 1px solid ${isMobile ? '#fff' : 'rgba(0,0,0,.5)'};
      transition: background-color 0.3s ease;
    }

    .${customBulletActiveName} {
      background: #fff;
    }
  `;

  const convertToFullUrl = (url?: string) => {
    return url?.startsWith('http') ? url : HTTP.defaults.baseURL + '/cms' + url;
  };

  useEffect(() => {
    const fetcher = async (url: string) => {
      setIsLoading(true);
      const data = await fetch(url);
      let parsed;
      try {
        parsed = await data.json();
      } catch {}
      const result = (parsed || []) as BannerType[];

      const filteredResult = Array.from(result).filter((banner) => {
        return (
          !!banner.attributes.pictureMobile.data ||
          !!banner.attributes.picture.data
        );
      });

      setBanners(filteredResult);
      setIsLoading(false);
      document.documentElement.dataset.sliderLoaded = '';
    };

    try {
      void fetcher(
        `/api/cms/banner?locale=${getFallbackLanguage(
          lang
        )}&licences=${license}&positions=top&random=false&categoryId=${categoryId}&limit=5`
      );
    } catch (error) {
      setIsLoading(false);
      document.documentElement.dataset.sliderLoaded = '';
      setIsBannerError(true);
    }
  }, [lang, categoryId, license]);

  const hasBanners = banners?.length > 0 && !isLoading && !isBannerError;

  return (
    <>
      {isLoading ? (
        <HStack justifyContent="center" alignItems="center" width="100%">
          <CircularProgress
            size="3em"
            isIndeterminate
            color="buttonPrimary"
            title={t('common:loadingGeneric')}
          />
        </HStack>
      ) : hasBanners ? (
        <Slide
          sliderTestId="slider-banner"
          containerStyle={{
            ...props,
            height: `${sliderHeight}px`
          }}
          bulletClass={customBulletName}
          bulletActiveClass={customBulletActiveName}
          bulletStyles={bulletStyles}
          showArrowNavigation={!isMobile}
          loop
        >
          {banners?.map((banner) => {
            return (
              <SlideItem
                key={banner.id}
                content={{
                  buttonText: banner.attributes.cta,
                  buttonUrl: banner.attributes.redirectUrl,
                  content: banner.attributes.description,
                  title: banner.attributes.title,
                  target: banner.attributes.ctaTarget
                }}
                imageUrl={convertToFullUrl(
                  isMobile
                    ? banner?.attributes.pictureMobile.data?.attributes.url
                    : banner?.attributes.picture.data?.attributes.url
                )}
                containerStyle={{ height: `${sliderHeight}px` }}
                extensionBackgroundColor={banner.attributes.extensionColorHex}
              />
            );
          })}
        </Slide>
      ) : null}
    </>
  );
};

export default FullSlider;

export type SlideItemProps = {
  content?: {
    title: string;
    content: string;
    buttonText: string;
    buttonUrl: string;
    target?: string;
  };
  imageUrl: string;
  containerStyle?: StyleProps;
  extensionBackgroundColor?: string | null;
  sliderHeight?: number;
};

export const SlideItem = ({
  content,
  imageUrl,
  containerStyle,
  extensionBackgroundColor,
  sliderHeight = SLIDER_HEIGHT
}: SlideItemProps) => {
  const isDesktop = useMediaQuery('(min-width: sm)');

  const heightBanners =
    getHeaderHeight(isDesktop) + getCategoriesHeight() + getJackpotsHeight();

  const textHeight = sliderHeight - heightBanners;

  // for debugging purpose
  // imageUrl = isDesktop
  //   ? 'https://storage.googleapis.com/gcs-gpv2-strapi-eu-prod/11_24_GP_promo_Golden_December_Daily_Boost_with_Pragmatic_Play_desktop_45a60d66a4/11_24_GP_promo_Golden_December_Daily_Boost_with_Pragmatic_Play_desktop_45a60d66a4.webp'
  //   : 'https://storage.googleapis.com/gcs-gpv2-strapi-eu-prod/11_24_GP_promo_Golden_December_Daily_Boost_with_Pragmatic_Play_mobile_62690814b6/11_24_GP_promo_Golden_December_Daily_Boost_with_Pragmatic_Play_mobile_62690814b6.webp';

  return (
    <Box justifyContent="flex-start" sx={{ ...containerStyle }}>
      <Box
        position="absolute"
        zIndex={0}
        width="100%"
        background={extensionBackgroundColor ?? undefined}
        height={['100%', '100%', '100%', `${sliderHeight}px`]}
        overflow={'hidden'}
      />

      <Container
        px={0}
        height={'100%'}
        position="relative"
        className="custom-slider-container"
        maxW={`calc( var(--chakra-sizes-container-xl) - 2rem)`}
        overflow={'hidden'}
      >
        <Img
          src={imageUrl}
          sx={{
            position: 'absolute',
            inset: 0,
            zIndex: 0,
            width: ['100%'],
            height: ['100%'],
            objectFit: ['contain'], // we cannot make a cover due to gradient on the edge
            objectPosition: [
              'bottom right',
              'bottom right',
              'bottom center',
              'bottom center'
            ]
            // for debugging purpose
            // bg: [
            //   'rgba(255 0 0 / .2)',
            //   'rgba(0 255 0 / .2)',
            //   'rgba(0 0 255 / .2)',
            //   'rgba(255 255 0 / .2)'
            // ]
          }}
        />
        {content ? (
          <VStack
            position={'absolute'}
            left={0}
            bottom={0}
            zIndex={1}
            alignItems="start"
            justifyContent="center"
            height={textHeight}
            maxWidth={['260px', '280px', '300px', '400px']}
            py={0}
            pl={['1rem', '1rem', '1rem', '2rem', '1rem']}
          >
            <VStack
              alignItems="start"
              mt={
                '-8px' /* to centering text visually - heading has some space on the "lineHeight top" */
              }
            >
              <Text
                textStyle="h2"
                fontSize={[toRem(24), toRem(24), toRem(24), toRem(32)]}
                textTransform="uppercase"
                as="h2"
              >
                {content.title}
              </Text>
              <Text
                as="p"
                fontSize={[toRem(14), toRem(14), toRem(14), toRem(16)]}
                style={{ margin: 0 }}
              >
                {content.content}
              </Text>
              <Button
                backgroundColor="buttonPrimary"
                as="a"
                href={content.buttonUrl}
                target={content.target}
                padding={`${toRem(7.5)} ${toRem(27)}`}
              >
                {content.buttonText}
              </Button>
            </VStack>
          </VStack>
        ) : null}
      </Container>
    </Box>
  );
};
