import Slider from '@/components/Slider/Slider';
import { ProviderButton } from '@/components';
import { MinimalProvider } from '@/components/GamesCatalog/types';
import { useCatalog } from '@/context/Catalog';
import { Text, Heading, Box, BoxProps, Button, HStack } from '@chakra-ui/react';
import { toPixel, toRem } from '@/helpers/toRem';
import { ProviderIcon } from '@/theme/Icons';
import React, { FC, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import { useRouter } from 'next/router';
import usePagination from '@/hooks/usePagination';
import {
  useCatalogBreakpoints,
  useProviderSliderBreakpoints
} from '@/hooks/useBreakpoints';
import { ChevronButtonRound } from '@/components/Button/ChevronButtonRound';
import {
  PaginationLine,
  PaginationLineRenderItemProps
} from '@/components/Pagination';
import { breakpoints } from '@/theme/base';
import useMediaQuery from '@/hooks/useMediaQuery';
import useResizeObserver from '@/hooks/useResizeObserver';

const orderByName = (a: MinimalProvider, b: MinimalProvider) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

type ProviderSliderProps = BoxProps & {
  isFilterOpen: boolean;
};

export const ProviderSlider: FC<ProviderSliderProps> = ({
  isFilterOpen,
  ...props
}: ProviderSliderProps) => {
  const useSlider = useMediaQuery('(max-width: xs)');
  const { t, lang } = useTranslation();
  const { dataStore, licence } = useCatalog();
  const providers: MinimalProvider[] = Object.values(
    dataStore.providers ?? {}
  ).sort(orderByName);
  const { gridItemsCount } = useProviderSliderBreakpoints(isFilterOpen);

  const {
    gotoNextPage,
    gotoPreviousPage,
    hasNextPage,
    hasPreviousPage,
    currentPage,
    totalPage,
    setCurrentPage
  } = usePagination({
    initialData: providers,
    perPage: gridItemsCount!,
    needFullPage: true
  });

  const hasPagination =
    gridItemsCount > 2 && (providers?.length ?? 0) >= gridItemsCount;

  if (!licence) return null;
  return (
    <Box {...props}>
      <HStack
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={toRem(24)}
        mx={[4, 'unset']}
      >
        <Heading
          data-testid="providers-title"
          size="h4"
          fontSize={[toRem(14), toRem(18)]}
          lineHeight={[toRem(20), toRem(22)]}
          as="h1"
          textTransform={'uppercase'}
          display={'flex'}
          gap={toRem(8)}
          alignItems={'center'}
        >
          <ProviderIcon width={toRem(30)} height={toRem(30)} />
          <Text as="span">{t('catalog:providers')}</Text>
        </Heading>
        <HStack>
          {!useSlider ? (
            <>
              <ChevronButtonRound
                aria-label="previous page"
                disabled={!hasPreviousPage}
                onClick={() => {
                  gotoPreviousPage();
                }}
                direction={'left'}
              />
              <ChevronButtonRound
                aria-label="next page"
                disabled={!hasNextPage}
                onClick={() => {
                  gotoNextPage();
                }}
                direction={'right'}
              />
            </>
          ) : null}
          <Button
            as="a"
            variant="outline"
            lineHeight={5}
            height={9}
            href={`${getFullUrlByLicense(licence, lang)}/providers/`}
            data-testid="ProvidersSeeAllRoute"
          >
            {t('catalog:seeAll')}
          </Button>
        </HStack>
      </HStack>
      {useSlider ? (
        <Slider enableArrows={false} gap={toRem(8)}>
          {providers.map((provider, index) => {
            return (
              <Box key={provider.id} pl={index === 0 ? [4, 0] : 0} mb={4}>
                <ProviderButton
                  provider={provider}
                  width={[toRem(150), toRem(160)]}
                />
              </Box>
            );
          })}
        </Slider>
      ) : (
        <PaginationLine
          width={'calc(100% + 1rem)'}
          maxWidth={'calc(100% + 1rem)'}
          ml={`-${toRem(21 / 2)}`} // - itemGap / 2
          mr={`-${toRem(21 / 2)}`}
          stackProps={{
            mb: 4
          }}
          currentPage={currentPage}
          totalPage={totalPage}
          setCurrentPage={setCurrentPage}
          data={providers}
          itemsPerPage={gridItemsCount}
          hasScroll={!hasPagination}
          itemGap={toRem(21)}
          renderItem={PaginationLineItem}
        />
      )}
    </Box>
  );
};

const PaginationLineItem = ({
  item,
  ...props
}: PaginationLineRenderItemProps<MinimalProvider>) => {
  return (
    <Box {...props}>
      <ProviderButton key={item.id} provider={item} width={'100%'} />
    </Box>
  );
};
