import useMediaQuery from '@/hooks/useMediaQuery';
import { toPixel, toRem } from '@/helpers/toRem';
import useResizeObserver from '@/hooks/useResizeObserver';
import { breakpoints } from '@/theme/base';

export const useCatalogBreakpoints = (isFilterOpen: boolean) => {
  const isMobile = useMediaQuery('(max-width: 35rem)');
  const isTablet = useMediaQuery('(min-width: 35rem) and (max-width: 50rem)');

  let gridItemsCount = isFilterOpen ? 4 : 5;

  if (isTablet) {
    gridItemsCount = 3;
  }

  if (isMobile) {
    gridItemsCount = 2;
  }

  return { gridItemsCount, isMobile, isTablet };
};

export const useProviderSliderBreakpoints = (isFilterOpen: boolean) => {
  const { width } = useResizeObserver();

  const breakpointLg = toPixel(breakpoints.lg);
  const itemWidth = 170;
  const averageTotalGap = 64;
  let gridItemsCount = Math.floor(
    (Math.min(width ?? breakpointLg, breakpointLg) - averageTotalGap) /
      itemWidth
  );

  gridItemsCount = isFilterOpen ? gridItemsCount - 2 : gridItemsCount;
  return { gridItemsCount };
};
