import { GameCatalogItem } from '@/services/getCatalog';
import useTranslation from 'next-translate/useTranslation';
import { Button, HStack, StackProps, Text } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';
import { FilterLinesIcon } from '@/theme/Icons';

export const CatalogHeader = ({
  gameCatalogItems,
  filters,
  ...props
}: StackProps & {
  gameCatalogItems: GameCatalogItem[];
  filters?: {
    onClick: () => void;
    filterVisibility: boolean;
    numberOfFilterSectionsActive: number;
  };
}) => {
  const { t } = useTranslation();
  return (
    <HStack
      justifyContent={'space-between'}
      alignItems="center"
      paddingY={'1rem'}
      borderColor="lightWhite"
      borderBottom={'1px solid'}
      marginBottom="3.5rem"
      marginTop={'3.5rem'}
      mx={[0, 0, 0, 4, 4]}
      {...props}
    >
      <Text fontSize={toRem(18)}>
        {gameCatalogItems?.length} {t('catalog:games')}
      </Text>
      {filters ? (
        <Text
          onClick={filters.onClick}
          cursor="pointer"
          display={'flex'}
          alignItems="center"
          fontSize={toRem(18)}
        >
          <FilterLinesIcon fontSize={'1.5rem'} />
          {filters.filterVisibility
            ? t('catalog:hideFilter')
            : t('catalog:showFilter')}
          {filters.numberOfFilterSectionsActive > 0 &&
          !filters.filterVisibility ? (
            <Button variant="outline" size="xs" marginLeft={'.5rem'}>
              {filters.numberOfFilterSectionsActive}
            </Button>
          ) : null}
        </Text>
      ) : null}
    </HStack>
  );
};
