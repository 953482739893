import { useCatalog } from '@/context/Catalog';
import { useFilters } from '@/context/Filters';
import { toRem } from '@/helpers/toRem';
import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import useMediaQuery from '@/hooks/useMediaQuery';
import { EyeIcon } from '@/theme/Icons';
import { DomainLicense } from '@/utils/multiDomains';
import {
  Box,
  Container,
  Text,
  useDisclosure,
  VisuallyHidden
} from '@chakra-ui/react';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { debounce } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CatalogFilter from '../Filter/CatalogFilter';
import SEO from '../SEO';
import { TournamentsHighLight } from '@/components/Tournaments';
import { SeoParams } from './CatalogWrapper';
import { TilesAll } from './TilesAll';
import { getTilesSectionsData, TilesSection } from './TilesSection';
import useLocalStorage from '@/hooks/useLocalStorage';
import { useAppSettings } from '@/context/AppSettings';
import { hasHighlightedTournaments } from '@/helpers/tournaments';
import FullSlider from './FullSlider';
import { PortalBanner, PortalHeader } from '@/components/Portal';
import { NavBar } from '@/components/Navbar';
import { JackpotsHeaderList, JackpotsList } from '@/components/Jackpots';
import { getCarouselMarginTop, hasJackpotsInHeader } from '@/helpers/header';
import { ProviderSlider } from '@/components/GamesCatalog/ProviderSlider';
import { CatalogHeader } from '@/components/GamesCatalog/CatalogHeader';
import { useRouter } from 'next/router';

const OriaBackground = dynamic(
  () => import('@/components/Videos/OriaBackground'),
  { ssr: false }
);
const DynamicCategories = dynamic(
  () => import('@/components/GamesCatalog/Categories')
);
const DynamicCatalogBackground = dynamic(
  () => import('@/components/GoldenPalace/CatalogBackground')
);

export type SeoContentCatalog = {
  descriptionHeader?: string;
  descriptionLead?: string;
  descriptionContent?: string;
  metaDescription?: string;
  metaKeywords?: string;
  metaTitle?: string;
  name: string;
  slug: string;
};
export type GameCatalogWithFiltersTypes = {
  seoContent?: SeoContentCatalog;
  seoParams?: SeoParams;
  bannersCategory?: string;
};

const GameCatalogWithFilters = ({
  seoContent,
  seoParams,
  bannersCategory = 'all'
}: GameCatalogWithFiltersTypes) => {
  const { t, lang } = useTranslation();
  const { dataStore, licence } = useCatalog();
  const { pathname } = useRouter();

  const {
    isTournamentEnabled,
    features: {
      homeProviders: { enabled: isHomeProvidersEnabled },
      jackpot: { enabled: isJackpotEnabled }
    }
  } = useAppSettings();

  const sections = useMemo(
    () =>
      getTilesSectionsData(
        dataStore?.sections || {},
        lang,
        licence as keyof DomainLicense
      ),
    [dataStore, lang, licence]
  );

  // const { userData, countOfLoginAttempts, isLoginFetching } = useAuth();
  // const { balance: userWalletBalance } = useWallet();
  const [isResized, setIsResized] = useState<number>(100);
  const { onOpen, onClose } = useDisclosure();
  const [nbOfImages, setNbOfImages] = useState<number>(4);
  const isMobile = useMediaQuery('(max-width: md)');

  const [filterVisibility, setFilterVisibility] = useLocalStorage(
    'filterVisibility',
    false
  );

  const { filteredGames, numberOfFilterSectionsActive } = useFilters();

  const seo = {
    description: seoContent?.metaDescription
      ? seoContent?.metaDescription
      : t('catalog:leMeilleurCatalogue'),
    keywords: seoContent?.metaKeywords ? seoContent?.metaKeywords : '',
    title: seoContent?.metaTitle
      ? seoContent?.metaTitle
      : t('catalog:jeuxDeCasino')
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefresh = useCallback(
    debounce(() => setIsResized(window?.performance.now()), 1000),
    []
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedRefresh);

    resizeObserver.observe(document?.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize filterVisible state based on localStorage
  const onToggleFilter = () => {
    setFilterVisibility((visibility) => !visibility);
  };

  // set number of images by row depending of filter if is open or not
  useEffect(() => {
    filterVisibility ? setNbOfImages(4) : setNbOfImages(5);
  }, [filterVisibility]);

  const showTournaments =
    isTournamentEnabled && hasHighlightedTournaments(dataStore);

  const [sliderHeight, marginTop, hasJackpotMini] = useMemo(() => {
    const sliderVisible = 220;
    const hasJackpotMini = hasJackpotsInHeader({
      enabled: isJackpotEnabled,
      pathname
    });
    const { css: marginTop, value: marginTopValue } = getCarouselMarginTop({
      isDesktop: !isMobile,
      withCategories: true,
      withJackpots: true
    });
    const sliderHeight = sliderVisible + marginTopValue * -1;
    return [sliderHeight, marginTop, hasJackpotMini];
  }, [isMobile, isJackpotEnabled, pathname]);

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        schemaType="Game"
        robotsContent={seoParams?.robots ?? 'all'}
      />

      <PortalHeader>
        <NavBar />

        <DynamicCategories data={dataStore?.categories || {}} />

        {hasJackpotMini ? <JackpotsHeaderList /> : null}
      </PortalHeader>

      <PortalBanner>
        <FullSlider
          categoryId={bannersCategory}
          sliderHeight={sliderHeight}
          marginTop={marginTop}
        />
      </PortalBanner>

      {WithFeaturesDisable(<OriaBackground />, 'OriaBackground')}
      {WithFeaturesDisable(<DynamicCatalogBackground />, 'CatalogBackground')}

      <VisuallyHidden>
        <Text as="h1">
          {t('catalog:jeuxDeCasino')}
          <br />
          {t('catalog:leMeilleurCatalogue')}
        </Text>
      </VisuallyHidden>
      <Container maxW="container.xl" padding={[0]}>
        <Box
          zIndex="100"
          position="relative"
          width={['100%']}
          // align="baseline"
        >
          <VisuallyHidden>
            <Text as="h1" fontSize="xl">
              {t('catalog:games')}
            </Text>
          </VisuallyHidden>

          {seoContent?.descriptionHeader && (
            <Text
              paddingTop="7.5rem"
              as="h2"
              textStyle="h1"
              marginBottom={'1rem'}
              textAlign={'center'}
              width={'100%'}
            >
              {seoContent?.descriptionHeader}
            </Text>
          )}
          {seoContent?.descriptionLead && (
            <Text
              as={'h2'}
              textStyle="h2"
              padding={'1rem 0'}
              textAlign={'center'}
              width={'100%'}
            >
              {seoContent?.descriptionLead}
            </Text>
          )}
          {!hasJackpotMini ? <JackpotsList mt={[6, 6, 10, 14]} /> : null}
        </Box>
        {/* anchor to catalog */}
        <div id="catalog"></div>

        {!isMobile ? (
          <CatalogHeader
            gameCatalogItems={filteredGames}
            filters={{
              onClick: onToggleFilter,
              filterVisibility,
              numberOfFilterSectionsActive
            }}
          />
        ) : null}
        <Box
          display={['grid']}
          gridGap="3rem"
          marginTop={'2rem'}
          gridTemplateColumns={
            filterVisibility && !isMobile
              ? ['auto', 'auto', 'auto', '276px auto']
              : 'auto'
          }
          px={[0, 8, 8, 4, 4]}
        >
          {!isMobile ? (
            <CatalogFilter
              isOpen={filterVisibility}
              onOpen={onOpen}
              onClose={onClose}
              padding={['1rem 0rem 1rem 0']}
            />
          ) : null}
          <Box
            width={'100%'}
            maxWidth={'100%'}
            overflow={'hidden'}
            padding={'1rem 0'}
          >
            {sections?.map((section) => (
              <TilesSection
                key={section.id}
                section={section.id}
                title={section.title}
                icon={section.icon}
                seeAllRoute={section.url}
                nbOfImages={nbOfImages}
                isFilterOpen={filterVisibility}
                mb={8}
                px={[4, 'unset']}
              />
            ))}

            {/* Tournaments */}
            {showTournaments ? (
              <TournamentsHighLight
                px={[4, 0]}
                mt={12}
                mb={12}
                css={{
                  '&:first-of-type': {
                    marginTop: 0
                  }
                }}
              />
            ) : null}

            <TilesAll
              games={filteredGames}
              px={[4, 'unset']}
              title={t('catalog:allGames')}
              iconSection={EyeIcon}
              nbOfImages={isMobile ? 2 : nbOfImages}
              isFilterOpen={filterVisibility}
            />
            {isHomeProvidersEnabled ? (
              <ProviderSlider
                mt={toRem(60)}
                isFilterOpen={!isMobile && filterVisibility}
              />
            ) : null}
          </Box>
        </Box>

        {/* content */}
        <Prose mt={16} px={[4, 8, 8, 4, 4]}>
          <Box
            className="seoContent"
            dangerouslySetInnerHTML={{
              __html: seoContent?.descriptionContent || ''
            }}
          ></Box>
        </Prose>
      </Container>
    </>
  );
};
export default GameCatalogWithFilters;
